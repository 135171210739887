import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';
import { JwtInterceptor } from './shared/interceptor/token.interceptor';
import { AppComponent } from './app.component';

import { NgChartjsModule } from 'ng-chartjs';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { NgxStripeModule } from 'ngx-stripe';
import { LoginByAdminComponent } from './login-by-admin/login-by-admin.component';
import { environment } from '../environments/environment';
import { AttendEventComponent } from './attendee/common-modals/attend-event/attend-event.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateProfileComponent } from './attendee/common-modals/update-profile/update-profile.component';
import { NgxAgoraModule, AgoraConfig } from 'ngx-agora';
import { MessagingService } from './shared/services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import * as firebase from 'firebase';
import { AppGlobalErrorhandler } from './shared/Error Handler/error-handler.service';
registerLocaleData(en);


const agoraConfig: AgoraConfig = {
  AppID: environment.AgoraAppId,
};

if (!firebase.apps.length) {
  firebase.initializeApp(environment.firebaseConfig);
}

navigator.serviceWorker.register(environment.FIREBASE_SW).then(registration => {
  firebase.messaging().useServiceWorker(registration);
});
@NgModule({
  declarations: [
    AppComponent,
    LoginByAdminComponent,
    AttendEventComponent,
    UpdateProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
    FormsModule, ReactiveFormsModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserModule,
    NgxAgoraModule.forRoot(agoraConfig)
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: ErrorHandler,
      useClass: AppGlobalErrorhandler
    },
    ThemeConstantService,
    DatePipe,
    MessagingService,
  ],
  entryComponents: [
    AttendEventComponent,
    UpdateProfileComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
