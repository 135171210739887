import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { PaymentComponent } from '../main/profile/payment/payment.component';
import { SafePipe } from './pipes/safe.pipe';
import { ImageCropUploadComponent } from './components/image-crop-upload/image-crop-upload.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { FilterVIPTableMembersPipe } from './pipes/filter-viptable-members.pipe';
import { FilterLoungeMembersPipe } from './pipes/filter-lounge-members.pipe';
import { DraggableDirective } from './directives/draggable.directive';
import { MovableDirective } from './directives/movable.directive';
import { ArraySortByDatePipe } from './pipes/array-sort-by-date.pipe';


// ng-zorro-antd modules
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDrawerServiceModule, NzDrawerModule, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SearchPipe,
    SafePipe,
    DateAgoPipe,
    ImageCropUploadComponent,
    FilterVIPTableMembersPipe,
    FilterLoungeMembersPipe,
    DraggableDirective,
    MovableDirective,
    ArraySortByDatePipe,
    NzDividerModule,
    NzCardModule,
    NzTabsModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzPopoverModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzBadgeModule,
    NzDropDownModule,
    NzMenuModule,
    NzListModule,
    NzTagModule,
    NzUploadModule,
    NzSelectModule,
    NzRadioModule,
    NzSpinModule,
    NzDrawerServiceModule,
    NzDrawerModule,
    NzFormModule,
    NzBreadCrumbModule,
    NzTableModule,
    NzStepsModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzMessageModule,
    NzNotificationModule,
    NzCalendarModule,
    NzTypographyModule,
    NzCheckboxModule,
    NzModalModule,
    NzInputModule,
    NzAvatarModule,
    NzPopconfirmModule
  ],
  imports: [
    RouterModule,
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NzModalModule,
    NzInputModule,
    NzDividerModule,
    NzCardModule,
    NzTabsModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzPopoverModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzBadgeModule,
    NzDropDownModule,
    NzMenuModule,
    NzListModule,
    NzTagModule,
    NzUploadModule,
    NzSelectModule,
    NzRadioModule,
    NzSpinModule,
    NzDrawerServiceModule,
    NzDrawerModule,
    NzFormModule,
    NzBreadCrumbModule,
    NzTableModule,
    NzStepsModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzMessageModule,
    NzNotificationModule,
    NzCalendarModule,
    NzTypographyModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzPopconfirmModule
  ],
  declarations: [
    SearchPipe,
    PaymentComponent,
    SafePipe,
    ImageCropUploadComponent,
    DateAgoPipe,
    FilterVIPTableMembersPipe,
    FilterLoungeMembersPipe,
    DraggableDirective,
    MovableDirective,
    ArraySortByDatePipe
  ],
  providers: [
    ThemeConstantService,
    NzDrawerService
  ],
  entryComponents: [PaymentComponent, ImageCropUploadComponent]
})

export class SharedModule { }
